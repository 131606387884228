<template>
    <div>
        <h2 class="section-header">
            <img src="@/assets/images/transaction-black.svg" alt="transactions"/>    
            My Property Transactions
        </h2>
        <div class="legends">
            <p>Pending <span class="pending"></span></p>
            <p>Approved <span class="approved"></span></p>
            <p>Rejected <span class="rejected"></span></p>
        </div>
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>PIN</th>
                        <th>DESCRIPTION</th>
                        <th>LOCATION</th>
                        <th>DATE</th>
                        <th>AMOUNT(&#8358;)</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <!-- pending action/status means that the property has not been approved yet -->
                <!-- approved action/status means that the property has been approved -->
                <!-- rejected action/status means that the property has been rejected -->
                <tbody>
                    <tr v-for="transaction in propertyTransactions" :key="transaction.id">
                        <td>{{transaction.id}}</td>
                        <td>{{transaction.pin}}</td>
                        <td>{{transaction.description}}</td>
                        <td>{{transaction.address}}</td>
                        <td>{{formatDate(transaction.created_at)}}</td>
                        <td>{{formatAmount(transaction.amount)}}</td>
                        <td><span :class="transaction.action.toLowerCase()"></span></td>
                    </tr>
                </tbody>
            </table>
            <div class="empty" v-if="propertyTransactions.length === 0 && fetchData === 'loaded'">No Records</div>
            <div class="loading-container" v-if="fetchData === 'loading'"><Loader/></div>
        </div>
    </div>
</template>

<script>
import transactions from "../services/transactions";
import formatDate from "../helpers/extractDate";
import currencyFormatter from "../helpers/currencyFormatter";

import Loader from './Loader';

export default {
    name: "Transactions",
    components: {
        Loader
    },
    data() {
        return {
            propertyTransactions: [],
            fetchData: "",
        }
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        formatAmount(value) {
            return currencyFormatter.toTwoDecimal(value);
        }
    },
    created() {
        let userID = this.$store.getters.getUser.id;
        this.fetchData = "loading";

        transactions.propertyTransactions(userID)
        .then(data => {
            if (data.success) {
                this.propertyTransactions = data.transactions;
            } else {
                this.$toast.error(data.error);
            }
        })
        .catch(() => {
            this.$toast.error("please check your network and refresh the page");
        })
        .finally(() => {
            this.fetchData = 'loaded';
        })
    }
}
</script>

<style scoped>
@import "../styles/table.css";
@import "../styles/section-header.css";
.legends {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}
.legends span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}
.legends p {
    font-size: 14px;
    font-family: "Gotham-Book";
    margin-right: 10px;
}
tr th:first-of-type, tr td:first-of-type, tr th:last-of-type, tr td:last-of-type {
    min-width: unset;
    text-align: center;
}
</style>